import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';

function App() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      // Replace with your backend API endpoint
      const url = "https://jes-backend-vercel.vercel.app/";

      axios
          .get(url)
          .then((response) => {
              setData(response.data);
              setLoading(false);
          })
          .catch((error) => {
              setError(error.message);
              setLoading(false);
          });
  }, []); // Empty dependency array ensures the effect runs only once.

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
      <div>
          <h1>In The making</h1>
          <pre>API: {JSON.stringify(data, null, 2)}</pre>
      </div>
  );
}

export default App;
